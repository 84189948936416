<template>
  <div>
    <b-container
      fluid
    >
      <b-tabs>
        <b-tab active title="Danh Sách Lớp Học">
          <b-table-filter
            :items="items"
            :fields="fields"
            title="Danh Sách Lớp Học"
            :totalRows="rowsLength"
            :currentPage="currentPage"
            :perPage="perPage"
            :filterOn="filterOn"
            :filter="filter"
            @remove-act="removeItem"
            @to-edit="toEdit"
          >
            <template #filterAdvanced>
              <b-row>
                <b-col cols="4">
                  <b-row no-gutters>
                    <b-col cols="12">
                      <b-row no-gutters>
                        <b-col>
                          <b-form-group
                            label="Tìm Khóa Học"
                          >
                            <div class="ft-class-list-opt">
                              <b-form-input @change="filterAdvanced('khoa_hoc', $event)" placeholder="Tìm..."/>
                            </div>
                          </b-form-group>
                        </b-col>
                        <b-col>
                          <b-form-group
                            label="Tìm Lớp Học"
                          >
                            <div class="ft-class-list-opt">
                              <b-form-input  placeholder="Tìm..."/>
                            </div>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col>
                      <b-row no-gutters>
                        <b-col>
                          <b-form-group
                            label="Tìm Giáo Viên"
                          >
                            <div class="ft-class-list-opt">
                              <b-form-input placeholder="Tìm..."/>
                            </div>
                          </b-form-group>
                        </b-col>
                        <b-col>
                          <b-form-group
                            label="Tìm Trợ Giảng"
                          >
                            <div class="ft-class-list-opt">
                              <b-form-input placeholder="Tìm..."/>
                            </div>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col cols="4" offset="4">
                  <b-row no-gutters>
                    <b-col>
                      <div class="ft-class-list-opt">
                        <b-form-group
                          label="Thời Gian Bắt Đầu"
                        >
                          <b-form-datepicker
                            v-model="filterDateFrom"
                            @input="columnFilter('tu_ngay')"
                            placeholder="Từ Ngày"
                            :date-format-options="{year: 'numeric', month: 'numeric', day: 'numeric'}"
                            :date-disabled-fn="dateDisabled"
                          />
                        </b-form-group>
                      </div>
                    </b-col>
                    <b-col>
                      <div class="ft-class-list-opt">
                        <b-form-group
                          label="Thời Gian Kết Thúc"
                        >
                          <b-form-datepicker
                            v-model="filterDateTo"
                            @input="columnFilter('den_ngay')"
                            placeholder="Đến Ngày"
                            :date-format-options="{year: 'numeric', month: 'numeric', day: 'numeric'}"
                            :date-disabled-fn="dateDisabled"
                          />
                        </b-form-group>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row no-gutters>
                    <b-col>
                      <b-form-group
                        label="Trạng Thái Lớp"
                      >
                        <div class="ft-class-list-opt">
                          <vue-select :options="[
                            {value: 1, label: 'Chính Thức'},
                            {value: 2, label: 'Học Thử'},
                          ]"   placeholder="Chọn..."/>
                        </div>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                        label="Trạng Thái Khóa Học"
                      >
                        <div class="ft-class-list-opt">
                          <vue-select :options="[
                            {value: 1, label: 'Chính Thức'},
                            {value: 2, label: 'Học Thử'},
                          ]"   placeholder="Chọn..."/>
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </template>
            <template #filterTable>
              <td style="width: 15%">
                  <b-form-input size="sm"
                    class="ft-search-form-them-khoa-hoc"
                    placeholder="Tìm Khóa Học"
                    @keyup="columnFilter('course')"
                    v-model="filterKhoaHoc"
                  />
              </td>
              <td style="width: 15%">
                  <b-form-input size="sm"
                    class="ft-search-form-them-khoa-hoc"
                    placeholder="Tìm Lớp Học"
                    @keyup="columnFilter('class_name')"
                    v-model="filterLopHoc"
                  />
              </td>
              <td style="width: 15%">
                  <b-form-input size="sm"
                    class="ft-search-form-them-khoa-hoc"
                    placeholder="Tìm Giáo Viên"
                    @keyup="columnFilter('teacher')"
                    v-model="filterGiaoVien"
                  />
              </td>
              <td style="width: 15%">
                  <b-form-input size="sm"
                    class="ft-search-form-them-khoa-hoc"
                    placeholder="Tìm Trợ Giảng"
                    @keyup="columnFilter('teacher_at')"
                    v-model="filterTeacherAt"
                  />
              </td>
              <td>
                  <b-form-datepicker  size="sm"
                    v-model="filterDateFrom"
                    @input="columnFilter('tu_ngay')"
                    placeholder="Từ Ngày"
                    :date-format-options="{year: 'numeric', month: 'numeric', day: 'numeric'}"
                  />
              </td>
              <td>
                  <b-form-datepicker size="sm"
                    v-model="filterDateTo"
                    @input="columnFilter('den_ngay')"
                    placeholder="Đến Ngày"
                    :date-format-options="{year: 'numeric', month: 'numeric', day: 'numeric'}"
                    :date-disabled-fn="dateDisabled"
                  />
              </td>
            </template>
            <template #rightAction>
              <b-button variant="success" :to="{ name: 'manager-classes-create'}"><feather-icon icon="PlusIcon" /> Thêm Lớp Học</b-button>
            </template>
          </b-table-filter>
        </b-tab>
        <b-tab title="Thời Khóa Biểu">
          <b-row>
            <b-col>
              <calendar />
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
    </b-container>
  </div>
</template>

<script>
import {
  BTabs, BTab, BFormGroup, BButton, BCol, BRow, BFormInput, BFormDatepicker, BContainer, 
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import { formatDate } from '@/@core/utils/filter'
import BTableFilter from '@/@core/components/datatable/BTableFilter.vue'
import VueSelect from 'vue-select'
import Calendar from '../schedule-time/Calendar.vue'

export default {
  components: {
    BTabs,
    BTab, 
    BFormGroup,
    BFormInput,
    BFormDatepicker,
    BCol,
    BRow,
    VueSelect,
    FeatherIcon,
    BTableFilter,
    BContainer,
    BButton,
    Calendar
},
  directives: {
    Ripple,
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      fields: [
        {
          label: 'Khóa Học',
          key: 'course',
        },
        {
          label: 'Tên Lớp',
          key: 'class_name',
        },
        {
          label: 'Giáo viên',
          key: 'teacher',
        },
        {
          label: 'Trợ Giảng',
          key: 'teacher_at',
        },
        {
          label: 'Giá Tiền',
          key: 'price',
        },
        {
          label: 'Học Sinh Đã Vào',
          key: 'student_current',
        },
        {
          label: 'Số Học Sinh Tối Đa',
          key: 'student_max',
        },
        {
          label: 'Tỷ Lệ Lắp Đầy',
          key: 'student_percent',
        },
        {
          label: 'Ngày Bắt Đầu',
          key: 'start_date',
        },
        {
          label: 'Ngày Kết Thúc',
          key: 'end_date',
        },
        {
          label: '',
          key: 'act',
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filterKhoaHoc: null,
      filterLopHoc: null,
      filterGiaoVien: null,
      filterTeacherAt: null,
      filter: null,
      filterOn: [], // danh sách cột
      count: 0,
      filterDateFrom: null,
      filterDateTo: null,
      selected: [],
      items: [
        {
          id: 1,
          course: 'Khóa 1',
          class_name: '1',
          teacher: 'Quốc DŨng',
          teacher_at: 'XXXXX',
          price: 20000,
          student_current: 2,
          student_max: 20,
          start_date: 1658925621,
          end_date: 1658925621,
        },
        {
          id: 2,
          course: 'Khóa 2',
          class_name: '2',
          teacher: 'Danh Sinh',
          teacher_at: 'XXXXX',
          price: 20000,
          student_current: 14,
          student_max: 20,
          start_date: (1658925621-100000),
          end_date: 1658925621,
        },
        {
          id: 3,
          course: 'Khóa 3',
          class_name: '1',
          teacher: 'XXXXX',
          teacher_at: 'XXXXX',
          price: 20000,
          student_current: 20,
          student_max: 20,
          start_date: (1658925621-100000),
          end_date: 1658925621,
        },
        {
          id: 4,
          course: 'Khóa 4',
          class_name: '1',
          teacher: 'XXXXX',
          teacher_at: 'XXXXX',
          price: 20000,
          student_current: 20,
          student_max: 20,
          start_date: (1658925621-100000),
          end_date: 1658925621,
        },
        {
          id: 4,
          course: 'Khóa 4',
          class_name: '1',
          teacher: 'XXXXX',
          teacher_at: 'XXXXX',
          price: 20000,
          student_current: 18,
          student_max: 20,
          start_date: (1658925621-100000),
          end_date: 1658925621,
        },
        {
          id: 4,
          course: 'Khóa 4',
          class_name: '1',
          teacher: 'XXXXX',
          teacher_at: 'XXXXX',
          price: 20000,
          student_current: 10,
          student_max: 20,
          start_date: (1658925621-100000),
          end_date: 1658925621,
        },
        {
          id: 4,
          course: 'Khóa 4',
          class_name: '1',
          teacher: 'XXXXX',
          teacher_at: 'XXXXX',
          price: 20000,
          student_current: 17,
          student_max: 20,
          start_date: (1658925621-100000),
          end_date: 1658925621,
        },
        {
          id: 4,
          course: 'Khóa 4',
          class_name: '1',
          teacher: 'XXXXX',
          teacher_at: 'XXXXX',
          price: 20000,
          student_current: 8,
          student_max: 20,
          start_date: (1658925621-100000),
          end_date: 1658925621,
        },
        {
          id: 4,
          course: 'Khóa 4',
          class_name: '1',
          teacher: 'XXXXX',
          teacher_at: 'XXXXX',
          price: 20000,
          student_current: 10,
          student_max: 20,
          start_date: (1658925621-100000),
          end_date: 1658925621,
        },
        {
          id: 4,
          course: 'Khóa 4',
          class_name: '1',
          teacher: 'XXXXX',
          teacher_at: 'XXXXX',
          price: 20000,
          student_current: 6,
          student_max: 20,
          start_date: (1658925621-100000),
          end_date: 1658925621,
        },
        {
          id: 4,
          course: 'Khóa 4',
          class_name: '1',
          teacher: 'XXXXX',
          teacher_at: 'XXXXX',
          price: 20000,
          student_current: 10,
          student_max: 20,
          start_date: (1658925621-100000),
          end_date: 1658925621,
        },
        {
          id: 4,
          course: 'Khóa 4',
          class_name: '1',
          teacher: 'XXXXX',
          teacher_at: 'XXXXX',
          price: 20000,
          student_current: 10,
          student_max: 20,
          start_date: (1658925621-100000),
          end_date: 1658925621,
        },
        {
          id: 4,
          course: 'Khóa 4',
          class_name: '1',
          teacher: 'XXXXX',
          teacher_at: 'XXXXX',
          price: 20000,
          student_current: 3,
          student_max: 20,
          start_date: (1658925621-100000),
          end_date: 1658925621,
        },
        {
          id: 4,
          course: 'Khóa 4',
          class_name: '1',
          teacher: 'XXXXX',
          teacher_at: 'XXXXX',
          price: 20000,
          student_current: 10,
          student_max: 20,
          start_date: (1658925621-100000),
          end_date: 1658925621,
        },
        {
          id: 4,
          course: 'Khóa 4',
          class_name: '1',
          teacher: 'XXXXX',
          teacher_at: 'XXXXX',
          price: 20000,
          student_current: 10,
          student_max: 20,
          start_date: (1658925621-100000),
          end_date: 1658925621,
        },
        {
          id: 4,
          course: 'Khóa 4',
          class_name: '1',
          teacher: 'XXXXX',
          teacher_at: 'XXXXX',
          price: 20000,
          student_current: 10,
          student_max: 20,
          start_date: (1658925621-100000),
          end_date: 1658925621,
        },
        {
          id: 4,
          course: 'Khóa 4',
          class_name: '1',
          teacher: 'XXXXX',
          teacher_at: 'XXXXX',
          price: 20000,
          student_current: 10,
          student_max: 20,
          start_date: (1658925621-100000),
          end_date: 1658925621,
        },
        {
          id: 4,
          course: 'Khóa 4',
          class_name: '1',
          teacher: 'XXXXX',
          teacher_at: 'XXXXX',
          price: 20000,
          student_current: 10,
          student_max: 20,
          start_date: (1658925621-100000),
          end_date: 1658925621,
        },
        {
          id: 4,
          course: 'Khóa 4',
          class_name: '1',
          teacher: 'XXXXX',
          teacher_at: 'XXXXX',
          price: 20000,
          student_current: 10,
          student_max: 20,
          start_date: (1658925621-100000),
          end_date: 1658925621,
        },
        {
          id: 4,
          course: 'Khóa 4',
          class_name: '1',
          teacher: 'XXXXX',
          teacher_at: 'XXXXX',
          price: 20000,
          student_current: 10,
          student_max: 20,
          start_date: (1658925621-100000),
          end_date: 1658925621,
        },
        {
          id: 4,
          course: 'Khóa 4',
          class_name: '1',
          teacher: 'XXXXX',
          teacher_at: 'XXXXX',
          price: 20000,
          student_current: 10,
          student_max: 20,
          start_date: (1658925621-100000),
          end_date: 1658925621,
        },
      ],
    }
  },
  mounted() {
    document.title = `Quản lý Lớp | ${this.$route.meta.pageTitle}`
  },
  computed: {
    rowsLength() {
      return this.items.length
    },
  },
  methods: {
    toEdit(data) {
      this.$router.push({ name: 'manager-classes-edit', params: { id: data.item.id } })
    },
    removeItem(id, k) {
      console.log('remove', id, k)
    },
    filterAdvanced(t, $event) {
    },
    dateDisabled(ymd, date) {
      const day = date.getDate()
      return day < new Date(new Date(this.filterDateFrom).getDate())
    },
    columnFilter(t) {
      this.filterOn = [t]
      // eslint-disable-next-line default-case
      switch (t) {
        case 'course' :
        {
          this.filter = this.filterKhoaHoc
        }
        break;
        case 'class_name' :
        {
          this.filter = this.filterLopHoc
        }
        break;
        case 'teacher' :
        {
          this.filter = this.filterGiaoVien
        }
        break;
        case 'teacher_at' :
        {
          this.filter = this.filterTeacherAt
        }
        break;
      }
    },
    onFiltered(filteredItems) {
      // console.log('onFilter', filteredItems)
      // this.items = filteredItems
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    onRowSelected(rows) {
      this.count = rows.length
      this.selected = rows
    },
  },
}
</script>

<style lang="scss" >
// HTML
.card {
  .card-header .heading-elements {
    position: static;
    background: red;
  }
}
</style>
<style>
.ft-class-list-opt{
  margin-right: 10px;
}
</style>