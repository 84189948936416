<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Thời Khóa Biểu</b-card-title>
    </b-card-header>
    <b-card-body>
      <schedule-time :form="form.scheduleTime" />
    </b-card-body>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import "@fullcalendar/core/vdom";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import "@fullcalendar/list/main.css";
// localization is optional

import { required } from '@validations'
import { BCardHeader, BCardTitle, BCard, BCardBody} from 'bootstrap-vue'

import ScheduleTime from '../../components/scheduleTime.vue'

export default {
  components: {
    ScheduleTime,
    BCardHeader,
    BCardTitle,
    BCard,
    BCardBody,
  },
  methods: {
  },
  data() {
    return {
      form: {},
      required,
    }
  },
}
</script>

<style>
  .color-input {
    width: 40%!important
  }
  .fc .fc-button-primary {
    background-color: #958cf2!important;
    border: 1px solid #d5d3e7
  }
  .fc .fc-button-active {
    background-color: #958cf2!important;
    border: 1px solid #d5d3e7
  }
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>